import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/home';
import About from './pages/about';
import Blog from './pages/news';
import Services from './pages/services';
import Technology from './pages/technologies';
import Contact from './pages/contact';
import MobileApps from './pages/mobile-apps';
import CRM from './pages/crm';
import UX from './pages/ux';
import HireUs from './pages/hire-us';
import SingleNews from './pages/single-news';
import SingleService from './pages/single-service';
import SingleTechnology from './pages/single-technology';
import NotFound from './pages/not-found';
import './App.css';

function App() {
  return (
    <div className="app">
      <Router>
        <Header />
        <main id="main">
          <Switch>
            <Route path="/mobile-apps" component={MobileApps}></Route>
            <Route path="/about" component={About}></Route>
            <Route path="/crm" component={CRM}></Route>
            <Route path="/ux" component={UX}></Route>
            <Route path="/hire-us" component={HireUs}></Route>
            <Route path="/blog/:id" component={SingleNews}></Route>
            <Route path="/blog" component={Blog}></Route>
            <Route path="/services/:id" component={SingleService}></Route>
            <Route path="/services" component={Services}></Route>
            <Route path="/technology/:id" component={SingleTechnology}></Route>
            <Route path="/technology" component={Technology}></Route>
            <Route path="/contact" component={Contact}></Route>
            <Route path="/not-found" component={NotFound}></Route>
            <Route exact path="/" component={Home}></Route>
            <Redirect to="/not-found"></Redirect>
          </Switch>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
