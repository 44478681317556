import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const Col = styled.div`
    margin-bottom: 30px;

    .col-wrap {
        position: relative;
        color: #fff;
        border-radius: 5px;
        overflow: hidden;

        &:before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(25,25,25,.37);
            z-index:1;
        }

        &:hover {
            .img-wrap {
                a{
                    &:before {
                        opacity: 0.85;
                    }
                }

                img {
                    transform: scale(1.1);
                }
            }

            .text {
                bottom: 30px;
            }
        }
    }

    .img-wrap {
        overflow: hidden;
        position: relative;

        a{
            position: relative;
            display: block;

            &:before {
                content:"";
                position:absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #9b111e;
                background-image: -webkit-gradient(linear,left top,left bottom,color-stop(10%,#9b111e),to(#000));
                background-image: -webkit-linear-gradient(#9b111e 10%,#000);
                background-image: linear-gradient(#9b111e 10%,#000);
                opacity: 0;
                z-index: 2;
                transition: opacity 0.3s ease;
            }
        }

        img {
            width:100%;
            transition: transform 0.3s ease;
        }
    }
    .text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -50px;
        padding: 10px;
        z-index: 5;
        transition: bottom 0.3s linear;
    }

    .title {
        font-weight: 600;

        @media only screen and (min-width:768px) {
            font-size: 24px;
        }

        a{
            color: #fff;
        }

        & + .btn {
            margin-top: 15px;

            @media only screen and (min-width:768px) {
                margin-top: 25px;
            }
        }
    }

    .sub-title {
        color: #c6c9d8;
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
    }

    .btn {
        font-size: 14px;
        padding: 5px 20px;
        letter-spacing:0.02em;
    }
`;

const CardNews = ({ tags, title, image, link }) => {
    return (
        <Col className="col news-card text-center">
            <div className="col-wrap">
                {image ?
                    <div className="img-wrap">
                        { link ?
                        <Link to={link}><img src={image} alt={title}/></Link>
                        : <img src={image} alt={title}/>
                        }
                    </div>
                    :''
                }

                <div className="text">
                {tags && <span className="sub-title">{
                    tags.map((tag,index) => { return <span key={index}>{`${tag}${tags.length > 1 ? ', ' : ''}`}</span>})}</span>}
                    {title &&
                        <h3 className="title">
                            {link ? <Link to={link}>{title}</Link> : `${title}` }
                        </h3>
                    }
                    {link && <Link to={link} className="btn btn-border">Read More</Link>}
                </div>
            </div>
        </Col>
    )

}

export default CardNews;