import React from 'react';
import {Link} from 'react-router-dom';

const  OurTechnologies = (props) => {
    // console.log(props);
    const { section_title:title , section_description:desc, section_link:link, text_alignment:align, section_padding:sec_padding, choose_background: background, technology_lists } = props.block;

    const padding = sec_padding.length>0 ? ' '+sec_padding.join(' ') : '';

    let bg = '';
    if(background === 'white') {
        bg = '';
    }else {
        bg = ' bg-'+background;
    }

    let text_align = '';

    if(align === 'left') {
        text_align = '';
    }else {
        text_align = ' text-'+align;
    }

    function heading() {
        if(title || desc || link) {
            return <div className={`heading-block${text_align}`}>
            {title && <h2 className="title">{title}</h2> }
            {desc && <div className="text" dangerouslySetInnerHTML={{__html: desc}} />}
            {link && <Link to={link.url} className="btn" target={link.url ? link.url : null}>{link.title}</Link>}
        </div>
        }
        return null;
    }

    return (
        <section className={`section column-with-icon our-technology-block${text_align}${padding}${bg}`}>
            <div className="container">
                {heading()}

                {technology_lists &&
                <div className="row d-flex">
                    {technology_lists.map(technology =>
                    <div className="col" key={technology.ID}>
                        <div className="col-wrap">
                            <a href={`/technologys/${technology.ID}`} className="link">Link</a>
                            <div className="icon-wrap">
                                {technology.acf.icon && <img src={technology.acf.icon.url} alt={technology.acf.icon.alt ? technology.acf.icon.alt : technology.acf.icon.title }/> }
                            </div>
                            <div className="text">
                                <h3 className="title">{technology.post_title}</h3>
                                {technology.post_excerpt && <p>{technology.post_excerpt}</p>}
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                }
            </div>
        </section>

    )
}

export default OurTechnologies
