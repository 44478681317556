import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo.png';
import InnerBanner from '../components/inner-banner';
import CardNews from '../components/card-news';
import Pagination from '../components/pagination';

function News() {
    const [news, setNews] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(3);

    useEffect(()=> {
        fetch('https://rubricitsolutions.com/staging/wp-json/wp/v2/posts/?_embed=1')
        .then(response=>response.json())
        .then(data => {
            // console.log(data);
            setNews(data);
        })
    }, []);

    const isNewsLoaded = news;

    if(!isNewsLoaded) {
        return <div className="preloader"><div > <img src={logo} alt="preloader"/> </div></div>;
    }

    //Get Current Post
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = isNewsLoaded.slice(indexOfFirstPost, indexOfLastPost);

    //Total Posts
    const totalPosts= isNewsLoaded.length;

    //Change Page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <>
            <InnerBanner title='News' image='' desc='' link=''></InnerBanner>
            <section className="section news-listing">
                <div className="container">
                    {currentPosts &&
                    <div className="row d-flex">
                        {currentPosts.map((news) => {
                            const tagsObj = news._embedded['wp:term'][0];
                            const tags = Object.entries(tagsObj).map(tag =>tag[1].name);
                            const image = news._embedded['wp:featuredmedia'][0].code !== "rest_forbidden" ? news._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url : '';
                            return <CardNews key={news.id} tags={tags} title={news.title.rendered} image={image} link={`/news/${news.id}`}> </CardNews>
                        })}
                    </div>
                    }

                    { totalPosts > postsPerPage &&
                    <Pagination paginate={paginate} currentPage={currentPage} postsPerPage={postsPerPage} totalPosts={totalPosts} /> }
                </div>
            </section>
        </>
    )
}

export default News
