import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const Banner = ({bannerSlides}) => {
    const settings = {
        dots: true,
        arrow: true,
        infinite: true,
        speed: 500,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    return (
        <Slider className="banner-slider"  {...settings}>
            { bannerSlides.map( (slide, index) => {
                return <div><div className="slide" key={index} style={{backgroundImage:  `url(${slide.image.url})`}}>
                {/* <img src={slide.image.url} alt={slide.title} /> */}
                <div className="container">
                    <div className="banner-text">
                        <h1 className="title">{slide.title}</h1>
                        <p className="description">{slide.description}</p>
                        {slide.link && <a href={slide.link.url} className="btn btn-border" target= {slide.link.target ? "_blank":null}>{slide.link.title}</a> }
                    </div>
                </div>
            </div>
            </div>
            })}
        </Slider>
    )
}

export default Banner
