import React from 'react';
import Page from './page';

function HireUs() {
    return (
        <Page className="hire-us-page" id={297} />
    )
}

export default HireUs
