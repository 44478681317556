import React from 'react';
import {Link} from 'react-router-dom'

function ColumnsWithIcon(props) {
    const { section_title:title , section_description:desc, section_link:link, text_alignment:align, choose_column, columns, section_padding:padding } = props.block;

    let text_align = '';

    if(align === 'left') {
        text_align = '';
    }else {
        text_align = 'text-'+align;
    }

    function heading() {
        if(title || desc || link) {
            return <div className="heading-block">
            {title && <h2 className="title">{title}</h2> }
            {desc && <div className="text" dangerouslySetInnerHTML={{__html: desc}} />}
            {link && <Link to={link.url} className="btn" target={link.url ? link.url : null}>{link.title}</Link>}
        </div>
        }
        return null;
    }

    return (
    <section className={`section column-with-icon${text_align ? ' '+text_align : ''}${padding.length>0 ? ' '+padding.join(' ') : ''}`}>
        <div className="container">
            {heading()}

            {columns &&
                <div className={`row d-flex col-${choose_column}`}>
                    {columns.map((col, index) => {
                        return <div className="col" key={index}>
                            <div className="col-wrap">
                                { col.link.url && <a href={col.link.url} class="link" target={col.link.url ? col.link.url : null}>Link</a> }
                                {col.icon &&
                                    <div className="icon-wrap">
                                        <img src={col.icon.url} alt={col.icon.alt ? col.icon.alt : col.icon.name}/>
                                    </div>
                                }

                                <div className="text">
                                    {col.title && <h3 className="title">{col.title}</h3> }
                                    {col.description && <p>{col.description}</p>}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            }
        </div>
    </section>
    )
}

export default ColumnsWithIcon;