import React from 'react';
import Page from './page';

function MobileApps() {
    return (
        <Page className="mobile-apps-page" id={291} />
    )
}

export default MobileApps
