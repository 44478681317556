import React, {useState, useEffect} from 'react';
import logo from '../assets/images/logo.png';
import Banner from '../components/banner';
import InnerBanner from '../components/inner-banner';
import TextBlock from '../components/text-block';
import HalfImageHalfContent from '../components/half-image-half-content';
import ColumnsWithIcon from '../components/columns-with-icon';
import OurServices from '../components/our-services';
import OurTechnologies from '../components/our-technology';
import Tabs from '../components/tabs';
import LatestNews from '../components/latest-news';
import ContactBlock from '../components/contact-block';

const Page = ({id}) => {
    // const abortController = new AbortController();
    const [page, setPage] = useState(null);
    useEffect(() => {
        fetch (`https://rubricitsolutions.com/staging/wp-json/wp/v2/pages/${id}`)
        .then(response => response.json())
        .then( data => {
            setPage(data);
        });

        // return function cancel() {
        // abortController.abort()
        // }
    },[id]);

    const isPageLoaded = page;

    if (!isPageLoaded) {
        return <div className="preloader"><div><img src={logo} alt="preloader"/></div></div>;
    }

    const blocks = isPageLoaded.acf.flexible_content;
    const bannerSlides =  isPageLoaded.acf.banner_slider;

    const banner = () => {
        if (id !== 32) {
            return <InnerBanner title={isPageLoaded.acf.ib_title ? isPageLoaded.acf.ib_title : isPageLoaded.title.rendered } image={isPageLoaded.acf.ib_image.url} desc={isPageLoaded.acf.ib_description} link={isPageLoaded.acf.ib_link}>
            </InnerBanner>
        }else {
            return <Banner bannerSlides={bannerSlides} />;
        }
    }

    if( blocks !== false && blocks !== '' && blocks !== undefined ) {
        return (
            <>
            { banner() }
            { blocks.map((block, index) => {
                if (block.acf_fc_layout === "text_block" && block.section_enable === true)
                return <TextBlock key={index} block={block} />

                if (block.acf_fc_layout === "half_image_half_content" && block.section_enable === true)
                return <HalfImageHalfContent key={index} block={block} />

                if (block.acf_fc_layout === "column_with_icon" && block.section_enable === true)
                return <ColumnsWithIcon key={index} block={block} />

                if (block.acf_fc_layout === "our_services" && block.section_enable === true)
                return <OurServices key={index} block={block} />

                if (block.acf_fc_layout === "our_technologies" && block.section_enable === true)
                return <OurTechnologies key={index} block={block} />

                if (block.acf_fc_layout === "tabs_block" && block.section_enable === true)
                return <Tabs key={index} block={block} />

                if (block.acf_fc_layout === "latest_news_block" && block.section_enable === true)
                return <LatestNews key={index} block={block} />

                if (block.acf_fc_layout === "contact_block" && block.section_enable === true)
                return <ContactBlock key={index} block={block} />
            })}
            </>
        );
    }
    return banner();
}

export default Page