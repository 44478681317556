import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import  GravityForm from "react-gravity-form";
import { APP_URI, API_KEY} from '../config';

const ContactWrapper = styled.section`
    .row {}

    .col {
        width: 100%;

        @media screen and (min-width: 768px) {
            width: 50%;
        }
    }
`;

const ContactBlock = (props) => {
    const { section_title:title , section_description:desc, section_link:link, text_alignment:align, section_padding:sec_padding, contact_content:content, form_id } = props.block;

    const padding = sec_padding.length>0 ? ' '+sec_padding.join(' ') : '';

    // console.log(props);

    let text_align = '';

    if(align === 'left') {
        text_align = '';
    }else {
        text_align = 'text-'+align;
    }

    function heading() {
        if(title || desc || link) {
            return <div className={`heading-block text-${text_align}`}>
            {title && <h2 className="title">{title}</h2> }
            {desc && <div className="text" dangerouslySetInnerHTML={{__html: desc}} />}
            {link && <Link to={link.url} className="btn" target={link.url ? link.url : null}>{link.title}</Link>}
        </div>
        }
        return null;
    }
    return (
        <ContactWrapper className={`section contact-block${padding}`}>
            <div className="container">
                {heading()}

                <div className="row d-flex">
                    <div className="col col-text">
                        {content &&  <div className="text" dangerouslySetInnerHTML={{__html: content}} /> }
                    </div>
                    <div className="col col-form">
                       {form_id && <GravityForm
                            backendUrl = {APP_URI + "/wp-json/gf/v2/forms"}
                            formID= {form_id}
                            populatedFields={{parameterName: "Value"}}
                            fetchOptions={{
                                headers: new Headers({
                                'Authorization': 'Bearer ' + API_KEY,
                                'Content-Type': 'application/json'
                                })
                            }}
                        />}
                    </div>
                </div>
            </div>
        </ContactWrapper>
    )
}

export default ContactBlock
