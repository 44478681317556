import React from 'react';

const NotFound = () => {
    return(
        <>
        <section className="inner-banner">
            <div className="container">
                <div className="banner-text">
                    <h1>404</h1>
                </div>
            </div>
        </section>
        <section className="section not-found text-center">
            <div className="container">
                <h2>Oops! That page can&rsquo;t be found.</h2>
		        <p>It looks like nothing was found at this location. Maybe go to homepage.</p>
                <a href="/" className="btn">Goto Homepage</a>
            </div>
        </section>
        </>
    )
}

export default NotFound