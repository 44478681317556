import React from 'react';
import styled from 'styled-components';

const PaginationWrapper = styled.div`
    ul {
        margin:0;
        list-style: none;
		display: flex;
		flex-wrap:wrap;
		justify-content: center;
		padding: 30px 0 0;
		li {
			margin: 0 5px;
        }

        .page-item {
            &.current {
              .page-link {
                    background: #9b111e;
                    pointer-events:none;
			    }
              }
            }
        }

		.page-link  {
			display:inline-block;
			vertical-align:middle;
			width: 30px;
			height: 30px;
			padding: 0 5px;
			text-align: center;
			line-height: 30px;
			background: gray;
			color: white;

			/* @include media('>=tablet') {
				width: 40px;
				height: 40px;
				line-height: 40px;
			} */

			&:hover {
				background: #9b111e;
			}

		}
	}
`;

const Pagination = ({postsPerPage, totalPosts, paginate, currentPage}) => {
    const pageNumbers = [];

    for(let i =1 ; i<= Math.ceil(totalPosts / postsPerPage); i++){
        pageNumbers.push(i);
    }

    return (
        <PaginationWrapper className="pagination">
            <ul>
            {pageNumbers.map(number => (
                <li key={number} className={`page-item ${currentPage === number ? 'current' : ''}`}>
                    <a href='!#' onClick={(e)=>{paginate(number); e.preventDefault()}} className="page-link">{number}</a>
                </li>
            ))}
            </ul>
        </PaginationWrapper>
    )
}

export default Pagination



