import React from 'react';
import Page from './page';

function UX() {
    return (
        <Page className="ux-page" id={295} />
    )
}

export default UX
