import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Preloader from '../components/preloader';

const Header = () => {
    const [logo, setLogo] = useState();
    const [alt, setAlt] = useState();
    const location = useLocation();
    const activeClass = (route) => { return location.pathname === route ? "active" : '' };

    function toggleMenu(e) {
        document.body.classList.toggle('nav-active');
        e.preventDefault();
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if(window.scrollY > 50 ) {
                document.body.classList.add('sticky');
            }else {
                document.body.classList.remove('sticky');
            }
        });

        const link = document.querySelectorAll('.nav-link');
        for( let i = 0; i < link.length; i++) {
            link[i].addEventListener('click',removeNav());
        }

        function removeNav() {
            document.body.classList.remove('nav-active');
        }

        fetch('https://rubricitsolutions.com/staging/wp-json/acf/v3/options/theme-options')
        .then(response => response.json())
        .then(data => {
            setLogo(data.acf.logo.url);
            setAlt(data.acf.logo.alt ? data.acf.logo.alt : data.acf.logo.title)
        });

    });
    return (
        <>
        {/* <Preloader /> */}
        <header id="header">
            <div className="container">
                <div className="logo">
                    <Link to="/">
                        <img src={logo} alt={alt} />
                    </Link>
                </div>

                <nav id="nav">
                    <a href="/" onClick = {(e) => toggleMenu(e)} className="nav-opener" rel="noopener noreferrer"><span>Opener</span></a>
                    <div className="drop">
                        <ul>
                            <li className= {activeClass('/mobile-apps')}><Link className="nav-link" to="/mobile-apps">Mobile Apps</Link></li>
                            <li className= {activeClass('/crm')}><Link className="nav-link" to="/crm">CRM</Link></li>
                            <li className= {activeClass('/ux')}><Link className="nav-link" to="/ux">UX</Link></li>
                            <li className= {activeClass('/about')}><Link className="nav-link" to="/about">About</Link></li>
                            <li className= {activeClass('/blog')}><Link className="nav-link" to="/blog">Blog</Link></li>
                            <li className= {activeClass('/services')}><Link className="nav-link" to="/services">Services</Link></li>
                            <li className= {activeClass('/hire-us')}><Link className="nav-link" to="/hire-us">Hire Us</Link></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
        </>
    )
}

export default Header
