import React, { useState, useEffect } from 'react'
import logo from '../assets/images/logo.png';
import BackToTop from '../components/back-to-top';

const Footer = () => {
    const [footer, setFooter] = useState('');
    useEffect(() => {
        fetch('https://rubricitsolutions.com/staging/wp-json/acf/v3/options/theme-options')
        .then(response => response.json())
        .then(data => {
            setFooter(data);
        });
    }, []);

    const isFooterLoaded = footer;


    if(!isFooterLoaded) {
        return <div className="preloader"><div > <img src={logo} alt="preloader"/> </div></div>;
    }

    const footerLogo = isFooterLoaded.acf.footer_logo.url ? isFooterLoaded.acf.footer_logo.url : null;
    const alt = isFooterLoaded.acf.footer_logo.alt ? isFooterLoaded.acf.logo.alt : isFooterLoaded.acf.logo.title;
    const facebookUrl = isFooterLoaded.acf.facebook_url ? isFooterLoaded.acf.facebook_url : null;
    const instagramUrl = isFooterLoaded.acf.instagram_url ? isFooterLoaded.acf.instagram_url : null;
    const linkedinUrl = isFooterLoaded.acf.linkedin_url ? isFooterLoaded.acf.linkedin_url : null;

    const footerTextTitle = isFooterLoaded.acf.footer_text_title;
    const footerText = isFooterLoaded.acf.footer_text;


    return (
        <>
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col col-logo">
                            <div className="footer-logo">
                                <a href="/">
                                    <img src={footerLogo} alt={alt} />
                                </a>
                            </div>
                            <ul className="social-networks">
                                {facebookUrl &&  <li>
                                    <a href={facebookUrl} rel="noopener noreferrer" target="_blank">
                                        <i className="fa fa-facebook"></i>
                                        facebook
                                    </a>
                                </li>
                                }
                                {instagramUrl &&  <li>
                                    <a href={instagramUrl} rel="noopener noreferrer" target="_blank">
                                        <i className="fa fa-instagram"></i>
                                        instagram
                                    </a>
                                </li>
                                }
                                {linkedinUrl &&  <li>
                                    <a href={linkedinUrl} rel="noopener noreferrer" target="_blank">
                                        <i className="fa fa-linkedin"></i>
                                        linkedin
                                    </a>
                                </li>
                                }
                            </ul>
                        </div>
                        <div className="col col-text">
                            {footerTextTitle && <strong className="title">{footerTextTitle}</strong>}
                            {footerText &&  <div className="text" dangerouslySetInnerHTML={{__html: footerText}} /> }
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="copyright">
                        <p>Copyright &copy; 2020 Rubric. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
        <BackToTop />
        </>
    )
}

export default Footer
