import React, {useState, useEffect} from 'react';
import logo from '../assets/images/logo.png';
import InnerBanner from '../components/inner-banner';

function SingleService(props) {
    const {id} = props.match.params;
    const [singlePage, setSinglePage] = useState(null);

    useEffect(() => {
        fetch (`https://rubricitsolutions.com/staging/wp-json/wp/v2/services/${id}`)
        .then(response => response.json())
        .then( data => {
            setSinglePage(data);
        });
    },[id]);

    const isDataLoaded = singlePage;

    if (!isDataLoaded) {
      return <div className="preloader"><div> <img src={logo} alt="preloader"/></div></div>;
    }

    if(!isDataLoaded.id) {
        window.location.href = '/not-found';
        // return <p>No Page Found</p>
    }

    return (
        <>
        <InnerBanner title={isDataLoaded.acf.ib_title ? isDataLoaded.acf.ib_title : isDataLoaded.title.rendered } image={isDataLoaded.acf.ib_image.url} desc={isDataLoaded.acf.ib_description} link={isDataLoaded.acf.ib_link} />

        <section className="section single-service">
            <div className="container">
                <h2>{singlePage.title.rendered}</h2>
                {singlePage.content.rendered && <div className="text" dangerouslySetInnerHTML={{__html: singlePage.content.rendered}} />}
            </div>
        </section>
        </>
    )

}

export default SingleService
