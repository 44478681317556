import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const TabList = styled.ul`
    display:flex;
    margin: 0 -20px 30px;
    padding: 0;
    list-style: none;
    @media screen and (max-width:767px){
        display:none;
    }
    li {
        cursor: pointer;
        position: relative;
        margin: 0 20px;
        display: inline-block;
        padding-bottom: 4px;
        font-size: 18px;
        font-weight: 500;
        outline: none;

        &:before {
            content: "";
            position: absolute;
            width: 30px;
            background: rgba(0,0,0,.2);
            transition: all .7s cubic-bezier(.19,1,.22,1) 0s;
            height: 2px;
            bottom: 0;
        }

        &.tab-active {
            color: #9b111e;
            &:before {
                width: 100%;
                background: #9b111e;
            }
        }
    }
`;

const TabPane = styled.div`
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: none;
    margin-bottom: 40px;

    @media screen and (min-width:768px){
        margin-bottom: 0;
    }

    &.active {
        opacity: 1;
        display: block;
    }
`;

const H3 = styled.h3`
    display:none;
    cursor: pointer;
    position: relative;
    padding-bottom: 4px;
    font-size: 18px;
    font-weight: 500;
    outline: none;
    margin: 0 0 20px;

    @media screen and (max-width:767px){
        display: block;
    }

    &:before {
        content: "";
        position: absolute;
        width: 30px;
        background: rgba(0,0,0,.2);
        transition: all .4s cubic-bezier(.19,1,.22,1) 0s;
        height: 2px;
        bottom: 0;
    }

    &.tab-active {
        color: #9b111e;
        &:before {
            width: 100%;
            background: #9b111e;
        }
    }
`;

const Tabs = (props) => {
    const { section_title:title , section_description:desc, section_link:link, text_alignment:align, tab_lists:tabsData, section_padding:padding } = props.block;
    // const [activeTab, setActiveTab] = useState(tabsData[0].title);
    const [activeTab, setActiveTab] = useState(0);

    let text_align = '';

    if(align === 'left') {
        text_align = '';
    }else {
        text_align = 'text-'+align;
    }

    function heading() {
        if(title || desc || link) {
            return <div className={`heading-block text-${text_align}`}>
            {title && <h2 className="title">{title}</h2> }
            {desc && <div className="text" dangerouslySetInnerHTML={{__html: desc}} />}
            {link && <Link to={link.url} className="btn" target={link.url ? link.url : null}>{link.title}</Link>}
        </div>
        }
        return null;
    }

    return (
        <div className={`section tabs-block${padding.length>0 ? ' '+padding.join(' ') : ''}`}>
            <div className="container">
                {heading()}
                <TabList className="tabs">
                    {tabsData.map((tab, index) => {
                        return <li key={index} onClick={() => setActiveTab(index)} className={activeTab === index ? "tab-active" : null} >{tab.title}</li>
                    })
                    }
                </TabList>
                <div className="tabs-container">
                    {tabsData.map((tab, index) => {
                        return <React.Fragment key={index}>
                            <H3 onClick={() => setActiveTab(index)} className={activeTab === index ? "tab-drawer-heading tab-active" : 'tab-drawer-heading'}>{tab.title}</H3>
                            <TabPane className={activeTab === index ? 'tabs-content active' : 'tabs-content'}>
                                <h3>{tab.title}</h3>
                                {tab.description &&  <div className="text" dangerouslySetInnerHTML={{__html: tab.description}} /> }
                                {tab.link && <Link to={tab.link.url} className="btn" target={tab.link.target ? '_blank' : null}>{tab.link.title}</Link>}
                            </TabPane>
                        </React.Fragment>
                    })
                    }
                </div>
            </div>
        </div>
    )
}

export default Tabs
