import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const HfImageHfContentWrapper = styled.section`
    &.img-right {
        .col-img {
            @media screen and ( min-width: 768px) {
                order: 1;
            }
        }
    }

    .col {
        width: 100%;

        @media screen and (min-width: 768px) {
            width: 50%;
        }
    }

    .col-img {
        margin-bottom: 30px;

        img {
            width: 100%;
        }
    }


`;

const HalfImageHalfContent = (props) => {
    const { title , description:desc, link, text_alignment:align, section_padding:sec_padding, choose_background: background,choose_image_position:image_position, image} = props.block;
    const padding = sec_padding.length>0 ? ' '+sec_padding.join(' ') : '';

    let bg = '';
    if(background === 'white') {
        bg = '';
    }else {
        bg = ' bg-'+background;
    }

    let imgPos = '';
    if(image_position === 'left') {
        imgPos = '';
    }else {
        imgPos = ' img-'+image_position;
    }

    let text_align = '';
    if(align === 'left') {
        text_align = '';
    }else {
        text_align = ' text-'+align;
    }

    function text() {
        if(title || desc || link) {
            return <div className="text">
            {title && <h2 className="title">{title}</h2> }
            {desc && <div className="text" dangerouslySetInnerHTML={{__html: desc}} />}
            {link && <Link to={link.url} className="btn" target={link.url ? link.url : null}>{link.title}</Link>}
        </div>
        }
        return null;
    }

    return (
        <HfImageHfContentWrapper className={`section half-image-half-content${padding}${bg}${imgPos}`}>
            <div className="container">
                <div className="row d-flex">
                    <div className="col col-img">
                        {image && <img src={image.url} alt={image.alt ? image.alt : image.title}/> }
                    </div>

                    <div className={`col col-text${text_align}`}>
                        {text()}
                    </div>
                </div>
            </div>
        </HfImageHfContentWrapper >
    )
}

export default HalfImageHalfContent
