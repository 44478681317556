import React from 'react';
import {Link} from 'react-router-dom';
import CardNews from '../components/card-news';

function LatestNews(props) {
    // console.log(props);
    const { section_title:title , section_description:desc, section_link:link, text_alignment:align, choose_background: bg, news_lists, section_padding:padding } = props.block;

    let text_align = '';

    if(align === 'left') {
        text_align = '';
    }else {
        text_align = 'text-'+align;
    }

    function heading() {
        if(title || desc || link) {
            return <div className={`heading-block ${text_align}`}>
            {title && <h2 className="title">{title}</h2> }
            {desc && <div className="text" dangerouslySetInnerHTML={{__html: desc}} />}
            {link && <Link to={link.url} className="btn" target={link.url ? link.url : null}>{link.title}</Link>}
        </div>
        }
        return null;
    }
    return (
        <section className={`section latest-news-block bg-${bg}${padding.length>0 ? ' '+padding.join(' ') : ''}`}>
            <div className="container">
                {heading()}

                {news_lists &&
                    <div className="row d-flex">
                        {news_lists.map((news) => {
                            return <CardNews key={news.ID} tags={news.categories} title={news.post_title} image={news.image} link={`/news/${news.ID}`}> </CardNews>
                        })}
                    </div>
                }
            </div>
        </section>
    )
}

export default LatestNews