import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Div = styled.div`

    > div {
        position: fixed;
        bottom: 30px;
        right: 30px;
        width: 50px;
        height: 50px;
        line-height: 66px;
        border-radius: 50%;
        background-color: #fff;
        text-align: center;
        overflow: hidden;
        z-index: 999!important;
        visibility: hidden;
        opacity: 0;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
        cursor: pointer;
        transition: opacity 0.3s ease, visibility 0.3s ease;
    }

    svg {
        font-size: 14px!important;
        color: #222;
        width: 27px;
        height: 27px;
    }
`;

const BackToTop = () => {
    const [isVisible, setIsVisible] = useState('true');

    useEffect(() => {
        document.addEventListener("scroll", function (e) {
            toggleVisibility();
        });
    }, []);

    const toggleVisibility = () => {
        if (window.pageYOffset > 200) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

    return (
        <Div className="back-to-top">
            {isVisible && <div onClick={scrollToTop} style={{ visibility: "visible", opacity: 1 }}>
            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="18 15 12 9 6 15"></polyline>
            </svg></div>
            }
        </Div>
    )
}

export default BackToTop
