import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo.png';
import InnerBanner from '../components/inner-banner';
import Pagination from '../components/pagination';

const Technologies = () => {
    const [technologies, setTechnologies] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    useEffect(()=> {
        fetch('https://rubricitsolutions.com/staging/wp-json/wp/v2/technologies/?_embed=1')
        .then(response=>response.json())
        .then(data => {
            // console.log(data);
            setTechnologies(data);
        })
    }, []);

    const istechnologyLoaded = technologies;

    // console.log(istechnologyLoaded)

    if(!istechnologyLoaded) {
        return <div className="preloader"><div > <img src={logo} alt="preloader"/> </div></div>;
    }

    //Get Current Post
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = istechnologyLoaded.slice(indexOfFirstPost, indexOfLastPost);

    //Total Posts
    const totalPosts= istechnologyLoaded.length;

    //Change Page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    return (
        <>
        <InnerBanner title='Our technology' image='' desc='' link=''></InnerBanner>
        <section className="section listing-block">
            <div className="container">
                {currentPosts &&
                <div className="row d-flex">
                    {currentPosts.map((technology) => {
                    const icon = technology.acf.icon !== 'false' ? technology.acf.icon.url : '';
                    const alt = technology.acf.alt !== 'false' ? technology.acf.alt : technology.acf.title;
                    const title = technology.title.rendered;
                    // const content = technology.content.rendered;
                    const excerpt = technology.excerpt.rendered;

                    return <div className="col" key={technology.id}>
                        <div className="col-wrap">
                            <a href={`/technology/${technology.id}`} className="link">Link</a>
                            <div className="icon-wrap">
                            {icon &&<img src={icon} alt={alt}/>}
                            </div>

                            <div className="text-wrap">
                                <h2 className="title">{title}</h2>
                                {excerpt && <div className="text" dangerouslySetInnerHTML={{__html: excerpt}} />}
                            </div>
                        </div>
                    </div>
                    })}
                </div>
                }

                { totalPosts > postsPerPage &&
                    <Pagination paginate={paginate} currentPage={currentPage} postsPerPage={postsPerPage} totalPosts={totalPosts} /> }
            </div>
        </section>
        </>
    )
}

export default Technologies
