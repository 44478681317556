import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const InnerBannerBackground = styled.section`
    background-image: url(${props => props.image });
`;

function InnerBanner ({ title, image, link, desc }) {
    return (
        <InnerBannerBackground className="inner-banner" image={image}>
            <div className="container">
                <div className="banner-text">
                    <h1 className="title">{title}</h1>
                    {desc && <p>{desc}</p>}
                    {link && <Link to={link.url} className="btn" target={link.target ? link.target : null }>{link.title}</Link> }
               </div>
            </div>
        </InnerBannerBackground>
    )
}

export default InnerBanner;