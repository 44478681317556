import React from 'react';
import {Link} from 'react-router-dom';

function TextBlock(props) {
    const { section_title:title , section_description:desc, section_link:link, text_alignment:align, section_padding:padding } = props.block;
    let text_align = '';

    if(align === 'left') {
        text_align = '';
    }else {
        text_align = 'text-'+align;
    }

    return (
        <section className={`section text-block${text_align ? ' '+text_align : ''}${padding.length>0 ? ' '+padding.join(' ') : ''}`}>
            <div className="container">
                {title && <h2>{title}</h2>}
                {desc &&  <div className="text" dangerouslySetInnerHTML={{__html: desc}} /> }
                {link && <Link to={link.url} className="btn" target={link.target ? '_blank' : null}>{link.title}</Link>}
            </div>
        </section>
    )
}

export default TextBlock