import React from 'react';
import Page from './page';

function CRM() {
    return (
        <Page className="crm-page" id={293} />
    )
}

export default CRM
